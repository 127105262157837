import { tokens } from "../contexts/theme/theme";

export const mockSunburstData = {
  name: "nivo",
  color: "hsl(284, 70%, 50%)",
  children: [
    {
      name: "viz",
      color: "hsl(204, 70%, 50%)",
      children: [
        {
          name: "stack",
          color: "hsl(71, 70%, 50%)",
          children: [
            {
              name: "cchart",
              color: "hsl(349, 70%, 50%)",
              loc: 80154,
            },
            {
              name: "xAxis",
              color: "hsl(76, 70%, 50%)",
              loc: 29900,
            },
            {
              name: "yAxis",
              color: "hsl(215, 70%, 50%)",
              loc: 146563,
            },
            {
              name: "layers",
              color: "hsl(184, 70%, 50%)",
              loc: 58679,
            },
          ],
        },
        {
          name: "ppie",
          color: "hsl(148, 70%, 50%)",
          children: [
            {
              name: "chart",
              color: "hsl(90, 70%, 50%)",
              children: [
                {
                  name: "pie",
                  color: "hsl(192, 70%, 50%)",
                  children: [
                    {
                      name: "outline",
                      color: "hsl(353, 70%, 50%)",
                      loc: 132786,
                    },
                    {
                      name: "slices",
                      color: "hsl(283, 70%, 50%)",
                      loc: 129956,
                    },
                    {
                      name: "bbox",
                      color: "hsl(195, 70%, 50%)",
                      loc: 89344,
                    },
                  ],
                },
                {
                  name: "donut",
                  color: "hsl(267, 70%, 50%)",
                  loc: 104538,
                },
                {
                  name: "gauge",
                  color: "hsl(278, 70%, 50%)",
                  loc: 197987,
                },
              ],
            },
            {
              name: "legends",
              color: "hsl(57, 70%, 50%)",
              loc: 53121,
            },
          ],
        },
      ],
    },
    {
      name: "colors",
      color: "hsl(279, 70%, 50%)",
      children: [
        {
          name: "rgb",
          color: "hsl(104, 70%, 50%)",
          loc: 4194,
        },
        {
          name: "hsl",
          color: "hsl(356, 70%, 50%)",
          loc: 21788,
        },
      ],
    },
    {
      name: "utils",
      color: "hsl(87, 70%, 50%)",
      children: [
        {
          name: "randomize",
          color: "hsl(292, 70%, 50%)",
          loc: 84176,
        },
        {
          name: "resetClock",
          color: "hsl(214, 70%, 50%)",
          loc: 92049,
        },
        {
          name: "noop",
          color: "hsl(180, 70%, 50%)",
          loc: 125247,
        },
        {
          name: "tick",
          color: "hsl(183, 70%, 50%)",
          loc: 147574,
        },
        {
          name: "forceGC",
          color: "hsl(186, 70%, 50%)",
          loc: 47130,
        },
        {
          name: "stackTrace",
          color: "hsl(44, 70%, 50%)",
          loc: 121664,
        },
        {
          name: "dbg",
          color: "hsl(355, 70%, 50%)",
          loc: 73662,
        },
      ],
    },
    {
      name: "generators",
      color: "hsl(228, 70%, 50%)",
      children: [
        {
          name: "address",
          color: "hsl(244, 70%, 50%)",
          loc: 7018,
        },
        {
          name: "city",
          color: "hsl(79, 70%, 50%)",
          loc: 154382,
        },
        {
          name: "animal",
          color: "hsl(35, 70%, 50%)",
          loc: 199797,
        },
        {
          name: "movie",
          color: "hsl(194, 70%, 50%)",
          loc: 190016,
        },
        {
          name: "user",
          color: "hsl(177, 70%, 50%)",
          loc: 8068,
        },
      ],
    },
    {
      name: "set",
      color: "hsl(74, 70%, 50%)",
      children: [
        {
          name: "clone",
          color: "hsl(110, 70%, 50%)",
          loc: 91032,
        },
        {
          name: "intersect",
          color: "hsl(235, 70%, 50%)",
          loc: 191476,
        },
        {
          name: "merge",
          color: "hsl(96, 70%, 50%)",
          loc: 175413,
        },
        {
          name: "reverse",
          color: "hsl(222, 70%, 50%)",
          loc: 92646,
        },
        {
          name: "toArray",
          color: "hsl(288, 70%, 50%)",
          loc: 177156,
        },
        {
          name: "toObject",
          color: "hsl(112, 70%, 50%)",
          loc: 154311,
        },
        {
          name: "fromCSV",
          color: "hsl(163, 70%, 50%)",
          loc: 56520,
        },
        {
          name: "slice",
          color: "hsl(257, 70%, 50%)",
          loc: 188043,
        },
        {
          name: "append",
          color: "hsl(358, 70%, 50%)",
          loc: 82965,
        },
        {
          name: "prepend",
          color: "hsl(270, 70%, 50%)",
          loc: 101108,
        },
        {
          name: "shuffle",
          color: "hsl(358, 70%, 50%)",
          loc: 104675,
        },
        {
          name: "pick",
          color: "hsl(23, 70%, 50%)",
          loc: 18774,
        },
        {
          name: "plouc",
          color: "hsl(197, 70%, 50%)",
          loc: 190887,
        },
      ],
    },
    {
      name: "text",
      color: "hsl(41, 70%, 50%)",
      children: [
        {
          name: "trim",
          color: "hsl(305, 70%, 50%)",
          loc: 133444,
        },
        {
          name: "slugify",
          color: "hsl(285, 70%, 50%)",
          loc: 64205,
        },
        {
          name: "snakeCase",
          color: "hsl(105, 70%, 50%)",
          loc: 148075,
        },
        {
          name: "camelCase",
          color: "hsl(106, 70%, 50%)",
          loc: 115897,
        },
        {
          name: "repeat",
          color: "hsl(35, 70%, 50%)",
          loc: 4688,
        },
        {
          name: "padLeft",
          color: "hsl(166, 70%, 50%)",
          loc: 160114,
        },
        {
          name: "padRight",
          color: "hsl(314, 70%, 50%)",
          loc: 87408,
        },
        {
          name: "sanitize",
          color: "hsl(354, 70%, 50%)",
          loc: 42890,
        },
        {
          name: "ploucify",
          color: "hsl(175, 70%, 50%)",
          loc: 65053,
        },
      ],
    },
    {
      name: "misc",
      color: "hsl(178, 70%, 50%)",
      children: [
        {
          name: "greetings",
          color: "hsl(214, 70%, 50%)",
          children: [
            {
              name: "hey",
              color: "hsl(294, 70%, 50%)",
              loc: 15030,
            },
            {
              name: "HOWDY",
              color: "hsl(212, 70%, 50%)",
              loc: 125184,
            },
            {
              name: "aloha",
              color: "hsl(52, 70%, 50%)",
              loc: 177084,
            },
            {
              name: "AHOY",
              color: "hsl(105, 70%, 50%)",
              loc: 66688,
            },
          ],
        },
        {
          name: "other",
          color: "hsl(225, 70%, 50%)",
          loc: 25962,
        },
        {
          name: "path",
          color: "hsl(289, 70%, 50%)",
          children: [
            {
              name: "pathA",
              color: "hsl(273, 70%, 50%)",
              loc: 98085,
            },
            {
              name: "pathB",
              color: "hsl(279, 70%, 50%)",
              children: [
                {
                  name: "pathB1",
                  color: "hsl(317, 70%, 50%)",
                  loc: 29262,
                },
                {
                  name: "pathB2",
                  color: "hsl(43, 70%, 50%)",
                  loc: 116900,
                },
                {
                  name: "pathB3",
                  color: "hsl(9, 70%, 50%)",
                  loc: 118855,
                },
                {
                  name: "pathB4",
                  color: "hsl(149, 70%, 50%)",
                  loc: 178818,
                },
              ],
            },
            {
              name: "pathC",
              color: "hsl(322, 70%, 50%)",
              children: [
                {
                  name: "pathC1",
                  color: "hsl(304, 70%, 50%)",
                  loc: 25706,
                },
                {
                  name: "pathC2",
                  color: "hsl(296, 70%, 50%)",
                  loc: 87668,
                },
                {
                  name: "pathC3",
                  color: "hsl(107, 70%, 50%)",
                  loc: 133310,
                },
                {
                  name: "pathC4",
                  color: "hsl(254, 70%, 50%)",
                  loc: 68823,
                },
                {
                  name: "pathC5",
                  color: "hsl(267, 70%, 50%)",
                  loc: 93893,
                },
                {
                  name: "pathC6",
                  color: "hsl(44, 70%, 50%)",
                  loc: 170754,
                },
                {
                  name: "pathC7",
                  color: "hsl(102, 70%, 50%)",
                  loc: 79468,
                },
                {
                  name: "pathC8",
                  color: "hsl(159, 70%, 50%)",
                  loc: 87922,
                },
                {
                  name: "pathC9",
                  color: "hsl(101, 70%, 50%)",
                  loc: 41517,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const mockRadarData = [
  {
    taste: "fruity",
    chardonay: 119,
    carmenere: 63,
    syrah: 86,
    // test: "random",
  },
  {
    taste: "bitter",
    chardonay: 54,
    carmenere: 36,
    syrah: 29,
  },
  {
    taste: "heavy",
    chardonay: 41,
    carmenere: 80,
    syrah: 95,
  },
  {
    taste: "strong",
    chardonay: 86,
    carmenere: 34,
    syrah: 76,
  },
  {
    taste: "sunny",
    chardonay: 43,
    carmenere: 29,
    syrah: 92,
  },
];

export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];

export const mockDataContacts = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
    city: "New York",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    address: "1234 Main Street, New York, NY 10001",
    city: "New York",
    zipCode: "13151",
    registrarId: 123512,
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    address: "3333 Want Blvd, Estanza, NAY 42125",
    city: "New York",
    zipCode: "87281",
    registrarId: 4132513,
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    address: "1514 Main Street, New York, NY 22298",
    city: "New York",
    zipCode: "15551",
    registrarId: 123512,
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    address: "11122 Welping Ave, Tenting, CD 21321",
    city: "Tenting",
    zipCode: "14215",
    registrarId: 123512,
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    address: "1234 Canvile Street, Esvazark, NY 10001",
    city: "Esvazark",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    address: "22215 Super Street, Everting, ZO 515234",
    city: "Evertin",
    zipCode: "51523",
    registrarId: 123512,
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    address: "4123 Ever Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 512315,
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    address: "51234 Avery Street, Cantory, ND 212412",
    city: "Colunza",
    zipCode: "111234",
    registrarId: 928397,
  },
  {
    id: 10,
    name: "Enteri Redack",
    email: "enteriredack@gmail.com",
    age: 42,
    phone: "(222)444-5555",
    address: "4123 Easer Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 533215,
  },
  {
    id: 11,
    name: "Steve Goodman",
    email: "stevegoodmane@gmail.com",
    age: 11,
    phone: "(444)555-6239",
    address: "51234 Fiveton Street, CunFory, ND 212412",
    city: "Colunza",
    zipCode: "1234",
    registrarId: 92197,
  },
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

export const mockTransactions = [
  {
    txId: "ANGAT",
    user: "BID",
    date: "2024/02/23 00:00:00",
    cost: "3000",
  },
  {
    txId: "ANGAT",
    user: "BID",
    date: "2024/02/23 00:05:00",
    cost: "2900",
  },
  {
    txId: "ANGAT",
    user: "BID",
    date: "2024/02/23 00:10:00",
    cost: "3100",
  },
  {
    txId: "ANGAT",
    user: "BID",
    date: "2024/02/23 00:20:00",
    cost: "3200",
  },
];

export const mockBarData = [
  {
    country: "AD",
    supply: 137,
    supplyColor: "hsl(229, 70%, 50%)",
    demand: 96,
    demandColor: "hsl(296, 70%, 50%)",
    dispatched: 72,
    dispatchedColor: "hsl(97, 70%, 50%)",
    donut: 140,
    donutColor: "hsl(340, 70%, 50%)",
  },
  {
    country: "AE",
    supply: 55,
    supplyColor: "hsl(307, 70%, 50%)",
    demand: 28,
    demandColor: "hsl(111, 70%, 50%)",
    dispatched: 58,
    dispatchedColor: "hsl(273, 70%, 50%)",
    donut: 29,
    donutColor: "hsl(275, 70%, 50%)",
  },
  {
    country: "AF",
    supply: 109,
    supplyColor: "hsl(72, 70%, 50%)",
    demand: 23,
    demandColor: "hsl(96, 70%, 50%)",
    dispatched: 34,
    dispatchedColor: "hsl(106, 70%, 50%)",
    donut: 152,
    donutColor: "hsl(256, 70%, 50%)",
  },
  {
    country: "AG",
    supply: 133,
    supplyColor: "hsl(257, 70%, 50%)",
    demand: 52,
    demandColor: "hsl(326, 70%, 50%)",
    dispatched: 43,
    dispatchedColor: "hsl(110, 70%, 50%)",
    donut: 83,
    donutColor: "hsl(9, 70%, 50%)",
  },
  {
    country: "AI",
    supply: 81,
    supplyColor: "hsl(190, 70%, 50%)",
    demand: 80,
    demandColor: "hsl(325, 70%, 50%)",
    dispatched: 112,
    dispatchedColor: "hsl(54, 70%, 50%)",
    donut: 35,
    donutColor: "hsl(285, 70%, 50%)",
  },
  {
    country: "AL",
    supply: 66,
    supplyColor: "hsl(208, 70%, 50%)",
    demand: 111,
    demandColor: "hsl(334, 70%, 50%)",
    dispatched: 167,
    dispatchedColor: "hsl(182, 70%, 50%)",
    donut: 18,
    donutColor: "hsl(76, 70%, 50%)",
  },
  {
    country: "AM",
    supply: 80,
    supplyColor: "hsl(87, 70%, 50%)",
    demand: 47,
    demandColor: "hsl(141, 70%, 50%)",
    dispatched: 158,
    dispatchedColor: "hsl(224, 70%, 50%)",
    donut: 49,
    donutColor: "hsl(274, 70%, 50%)",
  },
];

export const mockPieData = [
  {
    id: "hydro",
    label: "hydro",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "solar",
    label: "solar",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "coal",
    label: "coal",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "diesel",
    label: "diesel",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "geothermal",
    label: "geothermal",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockLineData = [
  {
    id: "Mindanao",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "00:00",
        y: 200,
      },
      {
        x: "2:00",
        y: 75,
      },
      {
        x: "4:00",
        y: 36,
      },
      {
        x: "6:00",
        y: 216,
      },
      {
        x: "8:00",
        y: 35,
      },
      {
        x: "10:00",
        y: 236,
      },
      {
        x: "12:00",
        y: 88,
      },
      {
        x: "14:00",
        y: 232,
      },
      {
        x: "16:00",
        y: 281,
      },
      {
        x: "18:00",
        y: 1,
      },
      {
        x: "20:00",
        y: 35,
      },
      {
        x: "22:00",
        y: 14,
      },
    ],
  },
  {
    id: "Visayas",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "00:00",
        y: 212,
      },
      {
        x: "2:00",
        y: 190,
      },
      {
        x: "4:00",
        y: 270,
      },
      {
        x: "6:00",
        y: 9,
      },
      {
        x: "8:00",
        y: 75,
      },
      {
        x: "10:00",
        y: 175,
      },
      {
        x: "12:00",
        y: 33,
      },
      {
        x: "14:00",
        y: 189,
      },
      {
        x: "16:00",
        y: 97,
      },
      {
        x: "18:00",
        y: 87,
      },
      {
        x: "20:00",
        y: 299,
      },
      {
        x: "22:00",
        y: 251,
      },
    ],
  },
  {
    id: "Luzon",
    color: tokens("dark").redAccent[200],
    data: [
      {
        x: "00:00",
        y: 191,
      },
      {
        x: "2:00",
        y: 136,
      },
      {
        x: "4:00",
        y: 91,
      },
      {
        x: "6:00",
        y: 190,
      },
      {
        x: "8:00",
        y: 211,
      },
      {
        x: "10:00",
        y: 152,
      },
      {
        x: "12:00",
        y: 189,
      },
      {
        x: "14:00",
        y: 152,
      },
      {
        x: "16:00",
        y: 8,
      },
      {
        x: "18:00",
        y: 197,
      },
      {
        x: "20:00",
        y: 107,
      },
      {
        x: "22:00",
        y: 170,
      },
    ],
  },
];

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];
