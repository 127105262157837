import { createContext } from "react";

export const DataContext = createContext({
  data: {
    regional_reserve: [],
    price_schedule: [],
    regional_summaries: [],
    radar_data: {
      data: [],
      date: "",
    },
    sunburst_data: {
      data: {},
      date: "",
    },
    barchart_data: [],
    telemetry_solar: [],
    telemetry_wind: [],
    spot_data: [],
  },

  setData: (data) => {},
});
