import { Box } from "@mui/material";
import Header from "../../components/Header";
import Telemetry from "../../components/telemery/telemetry";

const Line = (prop) => {
  const target = `telemetry_${prop["resource"].toLowerCase()}`;
  const title = `Aggregated ${prop["resource"]} Telemetry`;
  return (
    <Box m="20px">
      <Header title={title} subtitle="Luzon, Visayas, and Mindanao" />
      <Box height="75vh">
        <Telemetry resource={target} />
      </Box>
    </Box>
  );
};

export default Line;
