import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../contexts/theme/theme";
import Header from "../../components/Header";
// import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { DataContext } from "../../contexts/data/data_context";
import { useContext, useState, useEffect } from "react";
import { CONSTANTS } from "../../static/constant";

const Price_Schedule = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data, setData } = useContext(DataContext);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState([]);

  useEffect(() => {
    if (data.price_schedule.length === 0) {
      setLoading(true);
    }
    axios
      .get(CONSTANTS.prices_schedule_endpoint)
      .then((res) => {
        setLoading(false);
        setData({ ...data, price_schedule: res.data });
      })
      .catch((err) => {
        setError(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    // { field: "id", headerName: "ID" },
    {
      field: "RUN_TIME",
      headerName: "RUN_TIME",
      flex: 0.5,
    },
    {
      field: "MKT_TYPE",
      headerName: "MKT_TYPE",
      flex: 0.25,
    },
    {
      field: "TIME_INTERVAL",
      headerName: "TIME_INTERVAL",
      flex: 0.5,
    },
    {
      field: "REGION_NAME",
      headerName: "REGION_NAME",
      flex: 0.25,
    },
    {
      field: "RESOURCE_NAME",
      headerName: "RESOURCE_NAME",
      flex: 0.5,
    },
    {
      field: "RESOURCE_TYPE",
      headerName: "RESOURCE_TYPE",
      flex: 0.25,
    },
    {
      field: "SCHED_MW",
      headerName: "SCHED_MW",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.3,
    },
    {
      field: "LMP",
      headerName: "LMP",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.3,
    },
    {
      field: "LOSS_FACTOR",
      headerName: "LOSS_FACTOR",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.25,
    },
    {
      field: "LMP_SMP",
      headerName: "LMP_SMP",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.3,
    },
    {
      field: "LMP_LOSS",
      headerName: "LMP_LOSS",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.3,
    },
    {
      field: "LMP_CONGESTION",
      headerName: "LMP_CONGESTION",
      type: "number",
      headerAlign: "left",
      align: "left",
      flex: 0.3,
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Prices and Schedules"
        subtitle="Actual Real-Time Dispatch for Luzon, Visayas, and Mindanao"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          rows={data.price_schedule}
          columns={columns}
          loading={loading}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Price_Schedule;
