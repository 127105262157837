import { ResponsiveRadar } from "@nivo/radar";
import { useTheme } from "@mui/material";
import { tokens } from "../contexts/theme/theme";
import { useContext } from "react";
import { DataContext } from "../contexts/data/data_context";

const RTDRadarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data } = useContext(DataContext);

  return (
    <ResponsiveRadar
      data={data.radar_data.data}
      theme={{
        tooltip: {
          container: {
            color: "black",
          },
        },
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
              fontSize: isDashboard ? "1.0vh" : "1.5vh",
            },
          },
        },
        legends: {
          text: {
            fontSize: "1.0vh",
          },
        },
      }}
      keys={["CLUZ", "CVIS", "CMIN"]}
      fillOpacity={0.35}
      indexBy="COMMODITY_TYPE"
      valueFormat=">-.2f"
      margin={{ top: 48, right: 80, bottom: 40, left: 80 }}
      borderColor={{ from: "color" }}
      gridLabelOffset={isDashboard ? 17 : 30}
      dotSize={10}
      dotColor={{ theme: "background" }}
      dotBorderWidth={2}
      colors={{ scheme: "category10" }}
      blendMode="multiply"
      motionConfig="wobbly"
      legends={[
        {
          anchor: "top-left",
          direction: "column",
          translateX: -50,
          translateY: -30,
          itemWidth: 80,
          itemHeight: isDashboard ? 20 : 30,
          itemTextColor: colors.grey[300],
          symbolSize: isDashboard ? 10 : 15,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "rgba(0, 0, 0, .03)",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default RTDRadarChart;
