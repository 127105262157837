import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../contexts/theme/theme";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="You might have questions for us!" />

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What is this?
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ justifyContent: "space-between" }}>
          <Typography>
            This is a prototype for VisioX. We aim to provide bespoke data
            solutions for all your monitoring and business-as-usual needs.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Front-end stack
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ justifyContent: "space-between" }}>
          <Typography>Javascript</Typography>
          <Typography>React</Typography>
          <Typography>HTML</Typography>
          <Typography>CSS</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Back-end stack
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ justifyContent: "space-between" }}>
          <Typography>BeautifulSoup</Typography>
          <Typography>Django</Typography>
          <Typography>Celery</Typography>
          <Typography>Redis</Typography>
          <Typography>PostgreSQL</Typography>
          <Typography>AWS RDS (PostgreSQL)</Typography>
          <Typography>AWS S3</Typography>
          <Typography>AWS Lambda (to follow)</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Deployment stack
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ justifyContent: "space-between" }}>
          <Typography>Docker</Typography>
          <Typography>Nginx</Typography>
          <Typography>GUnicorn</Typography>
          <Typography>AWS Cloudformation (to follow)</Typography>
          <Typography>AWS EC2</Typography>
          <Typography>AWS Route 53</Typography>
          <Typography>AWS Codedeploy (to follow)</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Who are your market?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Generation Companies, Private Utilities, Public Electric
            Cooperatives, etc.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
