export const CONSTANTS = {
  regional_reserve_endpoint: "/api/rtd/regional_reserve/",
  prices_schedule_endpoint: "/api/rtd/price_schedule/",
  regional_summaries: "/api/rtd/regional_summaries/",
  radar_regional_reserve: "/api/rtd/latest_regional_reserve",
  sunburst_regional_summary: "/api/rtd/latest_regional_summary",
  latest_bar_data: "/api/rtd/latest_bar_data",
  solar_telemetry: `/ws/telemetry_solar/`,
  wind_telemetry: `/ws/telemetry_wind/`,
  spot_telemetry: `/ws/spot_data/`,
};
