import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import EmailIcon from "@mui/icons-material/Email";
// import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import TrafficIcon from "@mui/icons-material/Traffic";
import { tokens } from "../../contexts/theme/theme";
// import StatBox from "../../components/status";
import RTDBarChart from "../../components/barchart";
// import GeographyChart from "../../components/geography";
import Telemetry from "../../components/telemery/telemetry";
import Header from "../../components/Header";
// import PieChart from "../../components/piechart";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../contexts/data/data_context";
import RTDRadarChart from "../../components/radar";
import RTDSunburstChart from "../../components/sunburst";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data } = useContext(DataContext);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: "10px" }}
      >
        <Header title="DASHBOARD" subtitle="Real-Time Data and Dispatches" />
        <Typography variant="h4" fontWeight="600" color={"orange"}>
          For clients/recruiters or anyone interested, please check the{" "}
          <Link to={"/faq"}>FAQ</Link> page to know more about the tech-stack
          used for this project.
        </Typography>

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            {/* Download Market Reports
            <Typography variant="h6" color={colors.greenAccent[300]}>
              ::currently disabled
            </Typography> */}
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.greenAccent[300]}
              >
                Download Market Reports
              </Typography>
              <Typography variant="h7" color={colors.grey[500]} fontSize="10px">
                currently disabled
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="200px"
        gap="20px"
      >
        {/* ROW 1 */}
        {/* <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="17"
            subtitle="Emails"
            progress="0.75"
            hasProgress={false}
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="₱1000000"
            subtitle="Estimated Revenue"
            progress="0.50"
            increase="+21%"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1700"
            subtitle="New Customers"
            progress="0.30"
            increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Site Traffic Received"
            progress="0.80"
            increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box> */}

        {/* ROW 2 */}
        <Box
          gridColumn="span 5"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Aggregated Telemetry - Solar Assets (MW)
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                Luzon, Visayas, and Mindanao
              </Typography>
              <Typography variant="h6" color={colors.greenAccent[300]}>
                ::mock telemetry
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="300px" m="0 0 0 0">
            <Telemetry isDashboard={true} resource={"telemetry_solar"} />
          </Box>
        </Box>
        <Box
          gridColumn="span 5"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Aggregated Telemetry - Wind Assets (MW)
              </Typography>
              <Typography variant="h5" color={colors.greenAccent[500]}>
                Luzon, Visayas, and Mindanao
              </Typography>
              <Typography variant="h6" color={colors.greenAccent[300]}>
                ::mock telemetry
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="300px" m="0 0 0 0">
            <Telemetry isDashboard={true} resource={"telemetry_wind"} />
          </Box>
        </Box>
        <Box
          gridColumn="span 2"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            // display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Latest Spot Transactions
            </Typography>
            <Typography
              color={colors.greenAccent[200]}
              variant="h6"
              fontWeight="300"
            >
              ::mock data
            </Typography>
          </Box>
          {data.spot_data.toReversed().map((transaction, i) => (
            <Box
              key={`${transaction.txId}-${i}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h6"
                  fontWeight="600"
                >
                  {transaction.txId}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {transaction.user}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{transaction.date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="3px 8px"
                borderRadius="4px"
              >
                ₱{transaction.cost}
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        {/* <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h4" fontWeight="600">
            Generation Energy Mix
          </Typography>
          <Box height="350px" mt="-20px">
            <PieChart isDashboard={true} />
          </Box>
        </Box> */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          padding="30px"
        >
          <Typography
            variant="h4"
            fontWeight="600"
            // sx={{ marginBottom: "15px" }}
          >
            RTD Regional Summaries
          </Typography>
          <Typography variant="h6" color={colors.greenAccent[300]}>
            ::latest runtime - {data.sunburst_data.date}
          </Typography>
          <Box height="530px">
            <RTDSunburstChart isDashboard={true} />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h4" fontWeight="600">
            RTD Price Radar
          </Typography>
          <Typography variant="h6" color={colors.greenAccent[300]}>
            ::latest runtime - {data.radar_data.date}
          </Typography>
          <Box height="570px" mt="-8px">
            <RTDRadarChart isDashboard={true} />
          </Box>
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography
            variant="h4"
            fontWeight="600"
            // sx={{ padding: "30px 30px 0 30px" }}
          >
            RTD System Symmary
          </Typography>
          <Typography variant="h6" color={colors.greenAccent[300]}>
            ::7 latest runtimes
          </Typography>
          <Box height="580px" mt="0px">
            <RTDBarChart isDashboard={true} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
