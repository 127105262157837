import Dashboard from "./scenes/dashboard";
import Navbar from "./scenes/global/Navbar";
import TopBar from "./scenes/global/Topbar";
import { ColorModeContext, tokens, useMode } from "./contexts/theme/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import RegionalReserve from "./scenes/RTD/Regional_Reserve";
import PriceSchedule from "./scenes/RTD/Price_Schedule";
import Regional_Summaries from "./scenes/RTD/Regional Summaries";
import { Route, Routes } from "react-router-dom";
import FAQ from "./scenes/FAQ/faq";
import { useEffect, useState } from "react";
import { DataContext } from "./contexts/data/data_context";
import UserRegistration from "./scenes/forms/user_registration";
import Calendar from "./scenes/calendar/calendar";
import Bar from "./scenes/bar";
import Pie from "./scenes/pie";
import Line from "./scenes/lines";
import RTDRadar from "./scenes/radar";
import RTDSunburst from "./scenes/sunburst";
import Geography from "./scenes/geography";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import axios from "axios";
import { CONSTANTS } from "./static/constant";

const client_telemetry_solar = new W3CWebSocket(CONSTANTS.solar_telemetry);

const client_telemetry_wind = new W3CWebSocket(CONSTANTS.wind_telemetry);

const client_spot = new W3CWebSocket(CONSTANTS.spot_telemetry);

function App() {
  const [_, setError] = useState([]);
  const [theme, colorMode] = useMode();
  const [data, setData] = useState({
    regional_reserve: [],
    price_schedule: [],
    regional_summaries: [],
    radar_data: {
      data: [],
      date: "",
    },
    sunburst_data: {
      data: {},
      date: "",
    },
    barchart_data: [],
    telemetry_solar: [],
    telemetry_wind: [],
    spot_data: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(CONSTANTS.radar_regional_reserve)
        .then((res) => {
          setData((prevState) => ({ ...prevState, radar_data: res.data }));
        })
        .catch((err) => {
          setError(err.message);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(CONSTANTS.latest_bar_data)
        .then((res) => {
          setData((prevState) => ({ ...prevState, barchart_data: res.data }));
        })
        .catch((err) => {
          setError(err.message);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(CONSTANTS.sunburst_regional_summary)
        .then((res) => {
          setData((prevState) => ({ ...prevState, sunburst_data: res.data }));
        })
        .catch((err) => {
          setError(err.message);
        });
    };
    fetchData();
  }, []);

  console.log(data);

  const [telemetry_solar_data, setSolarTelemetry] = useState({
    luzon: [],
    visayas: [],
    mindanao: [],
  });

  const [telemetry_wind_data, setWindTelemetry] = useState({
    luzon: [],
    visayas: [],
    mindanao: [],
  });

  const returnTimeStamp = () => {
    var timestamp = new Date();
    const formattedtime = `${timestamp.getHours()}:${timestamp.getMinutes()}:${timestamp.getSeconds()}`;
    return formattedtime;
  };

  useEffect(() => {
    startTelemetry(
      client_telemetry_solar,
      "telemetry_solar",
      telemetry_solar_data,
      setSolarTelemetry
    );
  }, []);

  useEffect(() => {
    startTelemetry(
      client_telemetry_wind,
      "telemetry_wind",
      telemetry_wind_data,
      setWindTelemetry
    );
  }, []);

  const startTelemetry = (
    client_telemetry,
    target_key,
    telemetry,
    setTelemetry
  ) => {
    client_telemetry.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data)["data"];
      const formattedtime = returnTimeStamp();
      const tempTelemetry = telemetry;
      Object.entries(tempTelemetry).forEach(([key, value]) => {
        const tempDataTelemetry = value;

        tempDataTelemetry.push({
          x: formattedtime,
          y: dataFromServer[key],
        });

        if (tempDataTelemetry.length > 10) {
          tempDataTelemetry.shift();
        }

        setTelemetry((prevState) => ({
          ...prevState,
          [key]: tempDataTelemetry,
        }));
      });
      const formattedData = [
        {
          id: "Mindanao",
          color: tokens("dark").greenAccent[500],
          data: telemetry.mindanao,
        },
        {
          id: "Visayas",
          color: tokens("dark").blueAccent[400],
          data: telemetry.visayas,
        },
        {
          id: "Luzon",
          color: tokens("dark").redAccent[300],
          data: telemetry.luzon,
        },
      ];
      setData((prevState) => ({
        ...prevState,
        [target_key]: formattedData,
      }));
    };
  };

  useEffect(() => {
    client_spot.onmessage = (message) => {
      const formattedtime = returnTimeStamp();
      const dataFromServerSpot = JSON.parse(message.data)["data"];

      const temp_spot_data = data.spot_data;
      temp_spot_data.push({
        txId: dataFromServerSpot["bidder"],
        user: "market.player",
        date: formattedtime,
        cost: dataFromServerSpot["bid"],
      });

      if (temp_spot_data.length > 10) {
        temp_spot_data.shift();
      }

      setData((prevState) => ({ ...prevState, spot_data: temp_spot_data }));
    };
  }, []);

  return (
    <DataContext.Provider value={{ data, setData }}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app" style={{ outline: "1px solid red" }}>
            {/* <Navbar isSidebar={isSidebar} /> */}
            <Navbar />
            <main className="content">
              <TopBar />
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/rtd_regional_reserve"
                  element={<RegionalReserve />}
                />
                <Route path="/rtd_price_schedule" element={<PriceSchedule />} />
                <Route
                  path="/rtd_regional_summaries"
                  element={<Regional_Summaries />}
                />
                <Route path="/faq" element={<FAQ />} />
                <Route
                  path="/user_registration"
                  element={<UserRegistration />}
                />
                <Route path="/calendar_bid" element={<Calendar />} />
                <Route path="/bar" element={<Bar />} />
                <Route path="/pie" element={<Pie />} />
                <Route path="/rtd_radar" element={<RTDRadar />} />
                <Route path="/rtd_sunburst" element={<RTDSunburst />} />
                <Route
                  path="/solar_telemetry"
                  element={<Line resource={"Solar"} />}
                />
                <Route
                  path="/wind_telemetry"
                  element={<Line resource={"Wind"} />}
                />
                <Route path="/geography" element={<Geography />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </DataContext.Provider>
  );
}

export default App;
