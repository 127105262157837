import { Box } from "@mui/material";
import Header from "../../components/Header";
import BarChart from "../../components/barchart";

const Bar = () => {
  return (
    <Box m={"20px"}>
      <Header
        title={"RTD System Summary"}
        subtitle={"System (CLuz, CVis, CMin) Summary"}
      />
      <Box height={"75vh"}>
        <BarChart />
      </Box>
    </Box>
  );
};

export default Bar;
