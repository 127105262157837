import { Box } from "@mui/material";
import Header from "../../components/Header";
import PieChart from "../../components/piechart";

const Pie = () => {
  return (
    <Box m="20px">
      <Header
        title="Generation Energy Mix"
        subtitle="Latest dispatch for Luzon, Visayas, and Mindanao"
      />
      <Box height="75vh">
        <PieChart />
      </Box>
    </Box>
  );
};

export default Pie;
