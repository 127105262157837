import { ResponsiveSunburst } from "@nivo/sunburst";
import { useTheme } from "@mui/material";
import { tokens } from "../contexts/theme/theme";
import { DataContext } from "../contexts/data/data_context";
import { useContext } from "react";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const RTDSunburstChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { data } = useContext(DataContext);

  return (
    <ResponsiveSunburst
      data={data.sunburst_data.data}
      theme={{
        tooltip: {
          container: {
            color: "black",
          },
        },
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              // size: "100px",
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fontSize: "1.0vh",
            fill: colors.grey[100],
            // color: "green",
          },
        },
      }}
      margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
      id="name"
      value="loc"
      cornerRadius={2}
      borderWidth={2}
      //   borderColor={{ theme: "background" }}
      colors={{ scheme: "category10" }}
      inheritColorFromParent={false}
      childColor={{
        from: "color",
        modifiers: [["brighter", 0.1]],
      }}
      enableArcLabels={true}
      arcLabelsSkipAngle={10}
      animate={false}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 1.4]],
      }}
    />
  );
};

export default RTDSunburstChart;
