import { Box } from "@mui/material";
import Header from "../../components/Header";
import RTDRadarChart from "../../components/radar";
import { useContext } from "react";
import { DataContext } from "../../contexts/data/data_context";

const RTDRadar = () => {
  const { data } = useContext(DataContext);
  const last_update = `Last run time: ${data.radar_data.date}`;
  return (
    <Box m="20px">
      <Header title="RTD Radar Chart" subtitle={last_update} />
      <Box height="80vh">
        <RTDRadarChart />
      </Box>
    </Box>
  );
};

export default RTDRadar;
