import { Box } from "@mui/material";
import Header from "../../components/Header";
import RTDSunburstChart from "../../components/sunburst";
import { useContext } from "react";
import { DataContext } from "../../contexts/data/data_context";
import React from "react";

const RTDSunburst = () => {
  const { data } = useContext(DataContext);
  const last_update = `Last run time: ${data.sunburst_data.date}`;
  return (
    <Box m="20px">
      <Header title="RTD Regional Summaries" subtitle={last_update} />
      <Box height="80vh">
        <RTDSunburstChart />
      </Box>
    </Box>
  );
};

export default React.memo(RTDSunburst);
